import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from './Header';
import TableWrapper from './TableWrapper';
import Menu from './Menu';
import {
  getNickname,
  saveNickname,
  getSettings,
  saveSettings,
  getRecentTables
} from './db';

const DEFAULT_SETTINGS = {
  showLabels: true,
  rememberTableNicknames: false
};

const App = () => {

  const [nickname, setNickname] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [recentTables, setRecentTables] = useState(null);

  useEffect(() => {
    setNickname(getNickname() || '');
    setSettings(getSettings() || DEFAULT_SETTINGS);
    setRecentTables(getRecentTables() || {});
  }, []);

  const handleNicknameChange = (nickname = '') => {
    setNickname(nickname);
    saveNickname(nickname);
  }

  const handleSettingsChange = (settings) => {
    setSettings(settings);
    saveSettings(settings);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <React.Fragment>
      <Header
        nickname={nickname}
        handleNicknameChange={handleNicknameChange}
        settings={settings}
        toggleMenu={toggleMenu}
      />
      <div className="App">
        <TableWrapper
          nickname={nickname}
          settings={settings}
          recentTables={recentTables}
          setRecentTables={setRecentTables}
        />
        <Menu
          open={menuOpen}
          settings={settings}
          saveSettings={handleSettingsChange}
          setRecentTables={setRecentTables}
        />
      </div>
    </React.Fragment>
  );
}

export default App;
