export const roll = ({n, d, kh, m}) => {
    const result = rollSumHighest(n, d, kh);

    return {
        ...result,
        m,
        result: result.result + m
    }
};

export const rollAll = (n, d) => (
    new Array(parseInt(n)).fill(1).map(_ => (
        1 + Math.floor(Math.random() * d)
    ))
);

export const rollSum = (n, d) => {
    const rolls = rollAll(n, d);
    return {
        n,
        d,
        result: rolls.reduce((s, i) => (s + i), 0),
        rolls
    };
};

export const rollSumHighest = (n, d, kh) => {
    const rolls = rollAll(n, d);
    const sortedRolls = [...rolls].sort((a, b) => kh > 0 ? parseInt(b) - parseInt(a) : parseInt(a) - parseInt(b));
    return {
        n,
        d,
        kh,
        rolls,
        usedRolls: sortedRolls.slice(0, Math.abs(kh)),
        result: sortedRolls.slice(0, Math.abs(kh)).reduce((s, i) => (s + i), 0)
    };
};