import React from 'react';
import { Switch, Route } from 'react-router'; 
import Table from './Table';
import JoinTable from './JoinTable';

export const TableWrapper = ({ nickname, recentTables, settings, setRecentTables }) => {
    return (
        <Switch>
            <Route path="/" exact>
                <JoinTable 
                    recentTables={recentTables}
                />
            </Route>
            <Route path="/:tableId">
                <Table
                    nickname={nickname}
                    recentTables={recentTables}
                    settings={settings}
                    setRecentTables={setRecentTables}
                />
            </Route>
        </Switch>
    );
}

export default TableWrapper;