import React from 'react';
import { TextInput } from './components';
import './Header.scss';

export const Header = ({
    nickname,
    handleNicknameChange,
    settings,
    toggleMenu
}) => {
    const re = /[^a-zA-Z0-9_]/g;
    const useTableNN = settings.rememberTableNicknames;
    return (
        <nav id="navigation" className="Header">
            <span className="MenuButton fas fa-bars" onClick={toggleMenu}></span>
            <h1>DiceTable</h1>
            { !useTableNN && <TextInput
                value={nickname}
                onChange={e => handleNicknameChange(e.target.value.replace(re, ''))}
                placeholder="Nickname"
            /> }
        </nav>
    )
};
export default Header;
