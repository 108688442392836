import React, {useEffect, useRef, useState} from 'react';
import {appendRoll, subscribeToRolls} from './db';
import { useParams, useHistory } from 'react-router';
import {roll} from './roll';
import RollRow from './RollRow';
import { setRecentTables as saveRecentTables } from './db';
import './Table.scss';
import { Button, TextInput } from './components';

const MODES = {ROLL: true, CHAT: false};
const DICE = [2,3,4,6,8,10,12,20,100];

export const Table = ({
    nickname,
    recentTables,
    settings,
    setRecentTables
}) => {
    const { tableId } = useParams();
    const [rolls, setRolls] = useState([]);
    const [n, setN] = useState(1);
    const [d, setD] = useState(20);
    const [m, setM] = useState(0);
    const [kh, setKH] = useState(0);
    const [message, setMessage] = useState('');
    const [snack, setSnack] = useState('');
    const [mode, setMode] = useState(MODES.ROLL);
    const [tableNickname, setTableNickname] = useState((recentTables && recentTables[tableId]?.nickname) || '');
    const bottomRef = useRef(null);
    const history = useHistory();
    const rollRE = /^(?:[\\/]roll)?\s*(\d+)d(\d+)(?:k([hl])(\d+))?\s*(?:([+-])\s*(\d+))?\s*$/;
    const nicknameRE = /[^a-zA-Z0-9_]/g;
    const useTableNN = settings.rememberTableNicknames;

    useEffect(() => {
        if (recentTables !== null) {
            const newRecentTables = {
                ...recentTables,
                [tableId]: {
                    ...recentTables[tableId],
                    count: recentTables[tableId]?.count + 1 || 1,
                    lastVisited: Date.now()
                }
            };
            saveRecentTables(newRecentTables);
            setRecentTables(newRecentTables);
        }

        const unsubscribe = subscribeToRolls(tableId, 
            data => setRolls(data.map(r => ({
                ...r, 
                ...r.roll
            })))
        );

        return () => unsubscribe();
    }, [tableId]);

    useEffect(() => {
        if (useTableNN && !tableNickname?.length) {
            setTableNickname((recentTables && recentTables[tableId]?.nickname) || '');
        }
    }, [recentTables, tableId, tableNickname?.length, useTableNN]);

    useEffect(() => {
        bottomRef.current.scrollIntoView({
            behavior: 'smooth'
        });
    }, [rolls]);

    const toggleMode = () => {
        setMode(!mode);
    };

    const changeN = (newN) => {
        setN(Math.max(newN, 1));
    };

    const nextDie = () => {
        const nextI = (DICE.indexOf(d) + 1) % DICE.length;
        setD(DICE[nextI]);
    };

    const prevDie = () => {
        const prevI = (DICE.indexOf(d) - 1 + DICE.length) % DICE.length;
        setD(DICE[prevI]);
    };

    const clear = () => {
        setN(1);
        setD(20);
        setKH(0);
        setM(0);
    };

    const getNickname = () => {
        return useTableNN ? tableNickname : nickname;
    };

    const handleRoll = ({n = 1, d = 20, m = 0, kh = 1}) => {
        const result = roll({n, d, kh, m});
        if (!getNickname()) {
            toast('Nickname is required');
        } else {
            appendRoll({
                tableId,
                nickname: getNickname(),
                roll: result
            });
        }
    };

    const sendMessage = () => {
        const reResult = rollRE.exec(message);
        if (!getNickname()) {
            toast('Nickname is required');
        } else if (reResult) {
            const [_, n, d, hl, kh, pm, m] = reResult;
            appendRoll({
                tableId,
                nickname: getNickname(),
                roll: roll({
                    n,
                    d,
                    kh: `${(hl === 'l' ? -1 : 1) * (kh || n)}`,
                    m: (pm === '-' ? -1 : 1) * (m || 0),
                })
            });
            setMessage('');
        } else if (message) {
            appendRoll({
                tableId,
                nickname: getNickname(),
                message
            });
            setMessage('');
        }
    };

    const toast = (message) => {
        setSnack(message);

        setTimeout(() => {
            setSnack('');
        }, 3000);
    };

    const handleTableIdClick = () => {
        navigator.clipboard.writeText(`https://dice-table.albertjvm.ca/${tableId}`).then(() => {
            toast('Copied to clipboard');
        });
    };

    const handleRollClick = ({message, n, d, m, kh}) => {
        if (message) return;
        setN(parseInt(n));
        setD(d);
        setKH(n === kh ? 0 : parseInt(kh));
        setM(m);
        setMessage(`/roll ${n}d${d}${khText(kh)}${mText(m)}`);
    };

    const handleKeyDown = (e) => {
        if (mode !== MODES.ROLL) return;
        switch(e.key) {
            case '1': changeN(n + 1); break;
            case '2': nextDie(); break;
            case '3': setKH(kh + 1); break;
            case '4': setM(m + 1); break;
            case 'q': changeN(n - 1); break;
            case 'w': prevDie(); break;
            case 'e': setKH(kh - 1); break;
            case 'r': setM(m - 1); break;
            case 'Enter': handleRoll({n, d, kh: kh || n, m}); break;
            case 'Escape': clear(); break;
            default: 
                // console.warn(e.key);
                break;
        }
    };

    const khText = (kh) => kh > 0 ? `kh${kh}` : (kh < 0 ? `kl${-kh}` : '');
    const mText = (m) => m > 0 ? `\xa0+ ${m}` : (m < 0 ? `\xa0- ${-m}` : '');

    const leaveTableButton = () => (
        <Button
            onClick={() => history.push("/")}
        >
            Leave Table
        </Button>
    );

    const handleTableNNChange = (tnn) => {
        setTableNickname(tnn);

        const newRecentTables = {
            ...recentTables,
            [tableId]: {
                ...recentTables[tableId],
                nickname: tnn
            }
        };
        saveRecentTables(newRecentTables);
        setRecentTables(newRecentTables);
    };

    return (
        <div className="Table" onKeyDown={handleKeyDown} tabIndex="0">
            <section className="Table--Header">
                <span className='Table--TableId' onClick={handleTableIdClick}>Table: {tableId}</span>
                { !useTableNN && leaveTableButton() }
            </section>

            { useTableNN &&
                <section className="Table--Header">
                    <TextInput
                        placeholder="Nickname"
                        value={tableNickname}
                        onChange={e => handleTableNNChange(e.target.value.replace(nicknameRE, ''))}
                    />
                    { leaveTableButton() }
                </section>
            }

            <section className="Table--Body">
                {rolls.map((roll, i) => 
                   <RollRow key={i} {...roll} handleRollClick={handleRollClick} />
                )}
                <span ref={bottomRef}></span>
            </section>
            <section className="Table--Footer">
                    {mode === MODES.ROLL ? 
                        <React.Fragment>
                            <div className="Table--ButtonRow">
                                <Button onClick={toggleMode}><span className="Table--NarrowButton fas fa-keyboard"></span></Button>
                                <Button className='Table--RollButton' onClick={() => handleRoll({n, d, kh: kh || n, m})}>
                                    Press to roll:&nbsp;
                                    <span className='n'>{n}</span>
                                    <span className='d'>d{d}</span>
                                    <span className='kh'>{khText(kh)}</span>
                                    <span className='m'>{mText(m)}</span>
                                </Button>
                                <Button onClick={clear}><span className="Table--NarrowButton fas fa-undo-alt" /></Button>
                            </div>
                            { settings.showLabels && <div className="Table--LabelRow">
                                <span># of dice</span>
                                <span>Type of dice</span>
                                <span>Keep highest</span>
                                <span>Modifier</span>
                            </div>}
                            <div className="Table--ButtonRow">
                                <Button className='Table--SmallButton n' onClick={() => changeN(n + 1)} title="keyboard shortcut: [1]">+</Button>
                                <Button className='Table--SmallButton d' onClick={() => nextDie()} title="keyboard shortcut: [2]">+</Button>
                                <Button className='Table--SmallButton kh' onClick={() => setKH(kh + 1)} title="keyboard shortcut: [3]">+</Button>
                                <Button className='Table--SmallButton m' onClick={() => setM(m + 1)} title="keyboard shortcut: [4]">+</Button>
                            </div>
                            <div className="Table--ButtonRow">
                                <Button className='Table--SmallButton n' onClick={() => changeN(n - 1)} title="keyboard shortcut: [q]">-</Button>
                                <Button className='Table--SmallButton d' onClick={() => prevDie()} title="keyboard shortcut: [w]">-</Button>
                                <Button className='Table--SmallButton kh' onClick={() => setKH(kh - 1)} title="keyboard shortcut: [e]">-</Button>
                                <Button className='Table--SmallButton m' onClick={() => setM(m - 1)} title="keyboard shortcut: [r]">-</Button>
                            </div>
                        </React.Fragment>
                        :
                        <div className="Table--ButtonRow">
                            <Button onClick={toggleMode}><span className="Table--NarrowButton fas fa-dice-d20"></span></Button>
                            <TextInput
                                className="Table--ChatInput"
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') sendMessage();
                                }}
                            />
                            {message && <Button onClick={() => setMessage('')}><span className="Table--NarrowButton fas fa-times"></span></Button>}
                            <Button onClick={sendMessage}><span className="Table--NarrowButton fas fa-paper-plane"></span></Button>
                        </div>
                    }
            </section>
            <div className={`
                Table--Snackbar
                ${snack ? ' show' : ''}
            `}>
                {snack}
            </div>
        </div>
    );
}

export default Table;