import React from 'react';
import {arrayDiff} from './utils';

export const RollRow = (
    {
        n, 
        d, 
        kh, 
        m, 
        nickname='', 
        message = '',
        rolls = [], 
        usedRolls = [], 
        result,
        handleRollClick
    }
) => {
    return (
        <span
            className={message.length ? 'message' : 'roll'}
            title={`[${rolls.join(', ')}]`}
            onClick={() => handleRollClick({n, d, m, kh, message})}
        >
            <span>{nickname ? `${nickname}: ` : ''}</span>
            {message?.length ? 
                message
            : 
                <span>
                    <span style={{fontWeight: 'bold'}}>
                        {n}d{d}
                        {kh !== n && kh > 0 ? `kh${kh}` : (kh < 0 ? `kl${-kh}` : '')}
                        {m ? (m > 0 ? ` + ${m}` : ` - ${-m}`) : ''}&nbsp;
                        ➔ {result}
                    </span>

                    <span>&nbsp;[</span>
                    <span>{usedRolls.join(Math.abs(kh) === 1 ? ', ' : '+')}</span>
                    <span>{(rolls.length - usedRolls.length) ? (Math.abs(kh) === 1 ? ', ' : '+') : ''}</span>
                    <span style={{textDecoration: 'line-through'}}>{arrayDiff(rolls, usedRolls).join(Math.abs(kh) === 1 ? ', ' : '+')}</span>
                    <span>]</span> 
                </span>
            }
        </span>
    );
};

export default RollRow;