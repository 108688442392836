import React from 'react';
import { Button } from './components';
import { clearRecentTables } from './db';

const SETTINGS = [
    { key: 'showLabels', label: 'Show labels' },
    { key: 'rememberTableNicknames', label: 'Remember table specific nicknames' }
];

export const Menu = ({
    open,
    settings,
    saveSettings,
    setRecentTables
}) => {
    return (
        <div className={`Menu ${open ? 'open' : ''}`}>
            { SETTINGS.map(setting => (
                <div key={setting.key} className="Menu-Row" onClick={() => {
                    saveSettings({
                        ...settings,
                        [setting.key]: !settings[setting.key]
                    });
                }}>
                    <span
                        className={`fas fa-${settings[setting.key] ? 'check-square' : 'square'}`}
                    >&nbsp;</span>
                    <span>{setting.label}</span>
                </div>
            )) }
            <Button
                onClick={() => {clearRecentTables(); setRecentTables({})}}
            >
                Clear recent tables and nicknames
            </Button>
        </div>
    );
};

export default Menu;