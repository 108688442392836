import firebase from 'firebase/app';

const db = () => firebase.firestore();

export const getCurrentTableId = () => {
    return window.localStorage.getItem('currentTableId');
};
export const saveCurrentTableId = (tableId) => {
    if (!tableId) {
        window.localStorage.removeItem('currentTableId');
    } else {
        window.localStorage.setItem('currentTableId', tableId);
    }
};

export const getNickname = () => {
    return window.localStorage.getItem('nickname');
};
export const saveNickname = (nickname) => {
    window.localStorage.setItem('nickname', nickname);
};
export const getSettings = () => {
    return JSON.parse(window.localStorage.getItem('settings'));
};
export const saveSettings = (settings) => {
    window.localStorage.setItem('settings', JSON.stringify(settings));
};
export const getRecentTables = () => {
    return JSON.parse(window.localStorage.getItem('recentTables'));
};
export const setRecentTables = (recentTables) => {
    window.localStorage.setItem('recentTables', JSON.stringify(recentTables));
};
export const clearRecentTables = () => {
    window.localStorage.removeItem('recentTables');
};

export const subscribeToRolls = (tableId, callback) => {
    return db().collection('rolls')
        .where("tableId", "==", tableId)
        .orderBy("createdAt")
        .onSnapshot(
            snapshot => callback(snapshot.docs.map(doc => doc.data()))
        );
};

export const appendRoll = ({tableId, roll = {}, message = '', nickname}) => {
    db().collection('rolls').add({
        tableId,
        roll,
        nickname,
        message,
        createdAt: new Date()
    });
};