import React, {useState} from 'react';
import { useHistory } from 'react-router';
import { Button, TextInput } from './components';
import './JoinTable.scss';

export const JoinTable = ({ recentTables }) => {
    const history = useHistory();
    const [tableId, setTableId] = useState('');

    const randomTableId = () => {
        const list = "ABCDEFGHIJKLMNPQRSTUVWXYZ";
        return new Array(4).fill(0).map(i => list.charAt(Math.floor(Math.random() * list.length))).join('');
    }

    const handleNewTable = () => {
        history.push(`/${randomTableId()}`);
    }

    const handleJoinTable = () => {
        history.push(`/${tableId}`);
    }

    const sortedRecents = () => {
        if(recentTables === null) return [];
        return Object.keys(recentTables).map(k => ({
            tableId: k,
            ...recentTables[k]
        })).sort((a, b) => {
            return b.count - a.count;
        }).slice(0, 16);
    };

    return (
        <div className="JoinTable">
            <section>
                <div className="JoinTable--Row">
                    <label>Join a table:</label>
                </div>
                <div className="JoinTable--Row">
                    <TextInput
                        maxLength={4}
                        value={tableId}
                        onChange={e => setTableId(e.target.value.toUpperCase())}
                        placeholder='AAAA'
                    />
                    <Button
                        onClick={handleJoinTable}
                    >
                        Join
                    </Button>
                </div>
            </section>
            <section>
                <div className="JoinTable--Row">
                    <label>Or create a new table:</label>
                </div>
                <div className="JoinTable--Row">
                    <Button
                        onClick={handleNewTable}
                    >
                        New Table
                    </Button>
                </div>
            </section>
            {(recentTables && Object.keys(recentTables)?.length) ?
                <section>
                    <div className="JoinTable--Row">
                        <label>Rejoin a previous table:</label>
                    </div>

                    <div className="JoinTable--WrapRow">
                        {sortedRecents().map(recent => (
                            <Button key={recent.tableId} onClick={() => history.push(`/${recent.tableId}`)}>
                                {recent.tableId}
                            </Button>
                        ))}
                    </div>
                </section>
            : null}
        </div>
    );
}

export default JoinTable;