import './TextInput.scss';

export const TextInput = ({ className, ...props }) => {
    return (
        <input 
            type="text"
            className={`TextInput ${className}`}
            {...props}
        />
    );
};