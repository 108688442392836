import './Button.scss';

export const Button = ({ children, className, ...props }) => {
    return (
        <button
            className={`Button ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};