export const firebaseConfig = {
  apiKey: "AIzaSyAT35LNIZudrJkltVhughGH6mO9YRunZb8",
  authDomain: "address-search-gather.firebaseapp.com",
  databaseURL: "https://address-search-gather.firebaseio.com",
  projectId: "address-search-gather",
  storageBucket: "address-search-gather.appspot.com",
  messagingSenderId: "717275004677",
  appId: "1:717275004677:web:11ffcf867a95fdf32f816c"
};

  export default firebaseConfig;