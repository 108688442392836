export const arrayDiff = (a1, a2) => {
    const counter = {};

    a1.forEach(v => {
        if (counter.hasOwnProperty(v)) {
            counter[v] += 1;
        } else {
            counter[v] = 1;
        }
    });

    a2.forEach(v => {
        if (counter.hasOwnProperty(v)) {
            counter[v] -= 1;
        } else {
            counter[v] = -1;
        }
    });

    return Object.keys(counter).map(k => new Array(Math.abs(counter[k])).fill(k)).reduce((acc, v) => [...acc, ...v], []);
};
